export const stateSearchDefault = () => {
  return { status: false, value: '', data: [], count: 0, searchParams: [] }
}

export const stateAddsManyDatas = (state, action, actionDatas) => {
  return addsMany({ state, action, actionDatas })
}

export const stateAddsManyMoreDatas = (state, action, actionDatas) => {
  return addsMany({ state, action, actionDatas, addsMore: true })
}


const getStateDatas = (state) => {
  let storeDatas = state && state.data && state.data.slice()
  let searchDatas = state && state.search && state.search.data && state.search.data.slice()
  let storeDataGroups = state && state.dataGroups && state.dataGroups.slice()

  return {
    storeDatas,
    storeDataGroups,
    searchDatas
  }
}

const getIndexGroup = (dataGroups, groupId) => {
  return dataGroups && dataGroups.findIndex(g => g.groupId === groupId)
}

const setSearchData = ({ search, addsMore, actionDatas, searchDatas }) => {
  if (addsMore) {
    searchDatas = searchDatas.concat(actionDatas)
  } else {
    searchDatas = actionDatas
  }

  return {
    status: search.status,
    value: search.value,
    data: searchDatas,
    count: search.count || 0,
    params: search.params
  }
}

const setData = ({ addsMore, actionDatas, datas }) => {
  if (addsMore) {
    datas = datas.concat(actionDatas)
  } else {
    datas = actionDatas
  }

  return datas
}

const addsMany = ({ state, action, actionDatas, addsMore }) => {
  const { params } = action
  const { search } = params
  const { store } = params || {}

  let { storeDatas, storeDataGroups, searchDatas } = getStateDatas(state)
  let searchValue = stateSearchDefault()

  // for groupId
  if (store && store.groupId) {
    const groupIndex = getIndexGroup(storeDataGroups, store.groupId)

    // by index
    if (groupIndex !== -1) {

      // for search
      if (search) {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = setSearchData({
            search,
            addsMore,
            actionDatas,
            searchDatas: storeDataGroups[groupIndex].search.data
          })
        }

      // for data
      } else {
        if (storeDataGroups) {
          storeDataGroups[groupIndex].search = stateSearchDefault()
          storeDataGroups[groupIndex].data = setData({
            addsMore,
            actionDatas,
            datas: storeDataGroups[groupIndex].data
          })
        }

        // search is undefined
        /*if (storeDataGroups[groupIndex].search == undefined) {
          storeDataGroups[groupIndex].search = stateSearchDefault()
        }*/
      }

    // add new
    } else {
      storeDataGroups.push({
        groupId: store.groupId,
        data: actionDatas,
        search: stateSearchDefault()
      })
    }

  // no groupId
  } else {
    if (search) {
      searchValue = setSearchData({ search, addsMore, actionDatas, searchDatas })
    } else {
      storeDatas = setData({
        addsMore,
        actionDatas,
        datas: storeDatas
      })
    }
  }

  return {
    data: storeDatas,
    dataGroups: storeDataGroups || [],
    search: searchValue
  }
}
