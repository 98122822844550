
export const generateTypeName = (NAME) => {
  const ADDS = 'ADD_' + NAME[0]
  const ADDS_MORE = 'ADD_MORE_' + NAME[0]
  const ADD = 'ADD_' + NAME[1]
  const UPDATE = 'UPDATE_' + NAME[1]
  const REMOVE = 'REMOVE_' + NAME[1]

  const ADDS_MANY = 'ADDS_MANY_' + NAME[0]
  const ADDS_MANY_MORE = 'ADDS_MANY_MORE_' + NAME[0]
  const ADD_ONE = 'ADD_ONE_' + NAME[1]
  const UPDATE_ONE = 'UPDATE_ONE_' + NAME[1]
  const REMOVE_ONE = 'REMOVE_ONE_' + NAME[1]

  return {
    ADDS, ADDS_MORE, ADD, UPDATE, REMOVE,
    ADDS_MANY, ADDS_MANY_MORE, ADD_ONE, UPDATE_ONE, REMOVE_ONE,
  }
}

export const generateTypeFunctions = (NAME) => {
  const {
    ADDS, ADDS_MORE, ADD, UPDATE, REMOVE,
    ADDS_MANY, ADDS_MANY_MORE, ADD_ONE, UPDATE_ONE, REMOVE_ONE,
  } = generateTypeName(NAME)

  const adds = (datas, search=false, params=null) => ({ type: ADDS, datas, search, params })
  const adds_more = (datas, search=false) => ({ type: ADDS_MORE, datas, search })
  const add = (data, order) => ({ type: ADD, data, order })
  const update = (data, _id, event=null, updateFn=null) => ({ type: UPDATE, data, _id, event, updateFn })
  const remove = (_id, removeFn=null) => ({ type: REMOVE, _id, removeFn })

  const adds_many = ({ datas, params }) => ({ type: ADDS_MANY, datas, params })
  const adds_many_more = ({ datas, params }) => ({ type: ADDS_MANY_MORE, datas, params })

  return {
    adds, adds_more, add, update, remove,
    adds_many, adds_many_more
  }
}

