import apiCaller from 'client/core/utils/apiCaller'
import { checkAppActions, errorAlert } from './App/AppActions'
import "regenerator-runtime/runtime"


export const fetchDatasMany = async ({ dispatch, params, api, clean, adds_many, adds_many_more }) => {
  if (clean) {
    dispatch(adds_many({ datas: [], params }))
  } else {
    checkAppActions(dispatch, params, 'start')

    return apiCaller(api, 'get', params.body).then(res => {
      if (res.success) {
        const datas = res.data

        if (params.search && res.count) {
          params.search.count = res.count
        }

        if (!params.notSaveStore) {
          if (params.addsManyMore) {
            dispatch(adds_many_more({ datas, params }))
          } else {
            dispatch(adds_many({ datas, params }))
          }
        }

        checkAppActions(dispatch, params, 'success', res)
      } else {
        if (!params.notShowErrorAlert) {
          dispatch(errorAlert(res))
        }

        checkAppActions(dispatch, params, 'error')
      }

      return res
    })
  }
}

export const updateApi = async ({ dispatch, params={}, api }) => {
  checkAppActions(dispatch, params, 'start')

  return apiCaller(api, 'put', params.body).then(res => {
    if (res.success) {
      if (!params.notStore) {
        checkAppActions(dispatch, params, 'success', res)
      }
    } else {
      dispatch(errorAlert(res))
      checkAppActions(dispatch, params, 'error')
    }

    return res
  })
}



