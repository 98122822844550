
export const userValue = ({ user, lang='local' }) => {
  let firstNameLang, lastNameLang, name, nameLang, avatarName

  // gernaral
  if (user) {
    firstNameLang = user.firstName ? user.firstName[lang] : null
    lastNameLang = user.lastName ? user.lastName[lang] : null
    name = `${firstNameLang} ${lastNameLang}`
    nameLang = `${firstNameLang} ${lastNameLang}`
    avatarName = [firstNameLang, lastNameLang]

    return {
      ...user,
      firstNameLang,
      lastNameLang,
      nameLang,
      avatarName
    }
  }
}

export const authValue = ({ auth, lang='local' }) => {
  if (auth && auth.user && auth.user.customer) {
    auth.user.customer = customerValue({ customer: auth.user.customer, lang })
  }

  return auth
}

export const customerValue = ({ customer, lang='local' }) => {
  let firstNameLang, lastNameLang, companyNameLang, branchNameLang, nameLang, businessNameLang, contactNameLang, avatarName, addressLang, telFull, mobileFull
  let statusName, statusIcon, statusColor
  let telShort, mobileShort, corporateNameLang

  // gernaral
  if (customer) {
    firstNameLang = customer.firstName ? customer.firstName[lang] : null
    lastNameLang = customer.lastName ? customer.lastName[lang] : null
    businessNameLang = customer.businessName ? customer.businessName[lang] : null
    companyNameLang = customer.companyName ? customer.companyName[lang] : null
    branchNameLang = customer.branchName ? customer.branchName[lang] : null

    if (firstNameLang && lastNameLang) {
      contactNameLang = `${firstNameLang} ${lastNameLang}`
    } else if (firstNameLang && !lastNameLang) {
      contactNameLang = `${firstNameLang}`
    }

    if (!businessNameLang) {
      if (companyNameLang) {
        businessNameLang = companyNameLang
      } else {
        businessNameLang = contactNameLang
      }
    }

    if (customer.corporate) {
      if (businessNameLang) {
        nameLang = businessNameLang
      }
    } else {
      nameLang = businessNameLang ? businessNameLang : contactNameLang
    }

    if (nameLang) {
      avatarName = nameLang.split(' ')
    }

    // contact
    addressLang = customer.address ? customer.address[lang] : null
    telShort = customer.tel && customer.tel.number ? `${customer.tel.number}` : null
    telFull = customer.tel && customer.tel.number ? `${customer.tel.number}` : null

    mobileShort= customer.mobile && customer.mobile.number ? `${customer.mobile.number}` : null
    mobileFull = customer.mobile && customer.mobile.number ? `${customer.mobile.number}` : null


    // Status
    if (customer.status === 'open') {
      if (lang === 'local') statusName = 'เปิด'
      statusIcon = "public"
      statusColor = "success"

    } else if (customer.status === 'close') {
      if (lang === 'local') statusName = 'ปิด'
      statusIcon = "lock"
      statusColor = "warning"

    } else if (customer.status === 'trash') {
      if (lang === 'local') statusName = 'ถังขยะ'
      statusIcon = "delete"
      statusColor = "error"
    }

    if (customer.corporate) {
      corporateNameLang = 'นิติบุคล'
    } else {
      corporateNameLang = 'บุคลธรรมดา'
    }
  }

  return {
    ...customer,
    firstNameLang, lastNameLang, companyNameLang, nameLang, branchNameLang, contactNameLang, businessNameLang, corporateNameLang,
    avatarName,
    addressLang,
    telFull, mobileFull,
    statusName, statusIcon, statusColor,
    mobileShort, telShort
  }
}
